import logo from "./logo.png";
import "./App.css";

function App() {
  return (
    <>
      <div class="font-sans bg-gray-100">
        <header class="bg-pink  w-full py-12">
          <img
            src={logo}
            class="intro-1  max-w-sm  md:max-w-md mx-auto"
            alt="logo"
          />

          <header class="intro-2  text-center py-4">
            <h1 class="text-3xl md:text-5xl font-bold">Head Space Head Spa</h1>
            <p class="text-xl mt-4">
              <span class="intro-text-1 pr-2">
                It’s more than a treatment...
              </span>
              <span class="intro-text-2 pr-2">it’s an experience.</span>
            </p>

            <a
              target="_blank"
              href="https://bookings.gettimely.com/headspaceheadspa/bb/book"
              class="intro-text-4 btn  mt-4 text-white font-semibold py-2 px-4 rounded transition duration-300 inline-block"
            >
              Book Now
            </a>
          </header>
        </header>

        <svg
          id="wave"
          style={{ transform: "rotate(180deg)", transition: "0.3s" }}
          viewBox="0 0 1440 150"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
              <stop stop-color="rgba(255, 223, 223, 1)" offset="0%"></stop>
              <stop stop-color="rgba(255, 223, 223, 1)" offset="100%"></stop>
            </linearGradient>
          </defs>
          <path
            style={{ transform: "translate(0, 0px)", opacity: 1 }}
            fill="url(#sw-gradient-0)"
            d="M0,90L21.8,95C43.6,100,87,110,131,105C174.5,100,218,80,262,70C305.5,60,349,60,393,70C436.4,80,480,100,524,95C567.3,90,611,60,655,42.5C698.2,25,742,20,785,35C829.1,50,873,85,916,95C960,105,1004,90,1047,70C1090.9,50,1135,25,1178,22.5C1221.8,20,1265,40,1309,40C1352.7,40,1396,20,1440,17.5C1483.6,15,1527,30,1571,37.5C1614.5,45,1658,45,1702,52.5C1745.5,60,1789,75,1833,75C1876.4,75,1920,60,1964,47.5C2007.3,35,2051,25,2095,30C2138.2,35,2182,55,2225,52.5C2269.1,50,2313,25,2356,30C2400,35,2444,70,2487,70C2530.9,70,2575,35,2618,30C2661.8,25,2705,50,2749,62.5C2792.7,75,2836,75,2880,77.5C2923.6,80,2967,85,3011,75C3054.5,65,3098,40,3120,27.5L3141.8,15L3141.8,150L3120,150C3098.2,150,3055,150,3011,150C2967.3,150,2924,150,2880,150C2836.4,150,2793,150,2749,150C2705.5,150,2662,150,2618,150C2574.5,150,2531,150,2487,150C2443.6,150,2400,150,2356,150C2312.7,150,2269,150,2225,150C2181.8,150,2138,150,2095,150C2050.9,150,2007,150,1964,150C1920,150,1876,150,1833,150C1789.1,150,1745,150,1702,150C1658.2,150,1615,150,1571,150C1527.3,150,1484,150,1440,150C1396.4,150,1353,150,1309,150C1265.5,150,1222,150,1178,150C1134.5,150,1091,150,1047,150C1003.6,150,960,150,916,150C872.7,150,829,150,785,150C741.8,150,698,150,655,150C610.9,150,567,150,524,150C480,150,436,150,393,150C349.1,150,305,150,262,150C218.2,150,175,150,131,150C87.3,150,44,150,22,150L0,150Z"
          ></path>
        </svg>

        <div class="pb-12"></div>

        {/* <div class="max-w-6xl mx-auto px-8 lg:px-8 py-20 md:py-28">
          <section class="flex flex-col md:flex-row-reverse items-center justify-between">
            <div class="max-w-lg mb-8  md:mb-0">
              <h2 class="text-3xl font-semibold mb-4">What is a Head Spa?</h2>
              <p>
                A head spa, originating from Japan, is a serene treatment
                focusing on scalp health, now popular worldwide. Our mood-lit
                studio, offers a journey starting with hair shampooing, scalp
                cleansing, and concluding with moisturizing treatments using
                oils and a luxury hair mask. The experience includes massages of
                the face, neck, and upper arms with hot stones and steam, ending
                with a hot towel wrap and blow-dry. This head spa isn't just a
                treatment; it's an indulgent experience that invites deep
                relaxation or even sleep. Treat yourself to more than just
                care—it's a rejuvenating escape.
              </p>
            </div>
            <img
              src="/a.jpg"
              alt="Head Spa Image"
              class="w-full max-w-md rounded-xl my-4 md:my-0 shadow-xl"
            />
          </section>
        </div> */}

        <div class="bg-white">
          <svg
            id="wave"
            style={{ transform: "rotate(180deg)", transition: "0.3s" }}
            viewBox="0 0 1440 150"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                <stop stop-color="rgba(255, 223, 223, 1)" offset="0%"></stop>
                <stop stop-color="rgba(255, 223, 223, 1)" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              style={{ transform: "translate(0, 0px)", opacity: 1 }}
              fill="#F3F4F6"
              d="M0,90L21.8,95C43.6,100,87,110,131,105C174.5,100,218,80,262,70C305.5,60,349,60,393,70C436.4,80,480,100,524,95C567.3,90,611,60,655,42.5C698.2,25,742,20,785,35C829.1,50,873,85,916,95C960,105,1004,90,1047,70C1090.9,50,1135,25,1178,22.5C1221.8,20,1265,40,1309,40C1352.7,40,1396,20,1440,17.5C1483.6,15,1527,30,1571,37.5C1614.5,45,1658,45,1702,52.5C1745.5,60,1789,75,1833,75C1876.4,75,1920,60,1964,47.5C2007.3,35,2051,25,2095,30C2138.2,35,2182,55,2225,52.5C2269.1,50,2313,25,2356,30C2400,35,2444,70,2487,70C2530.9,70,2575,35,2618,30C2661.8,25,2705,50,2749,62.5C2792.7,75,2836,75,2880,77.5C2923.6,80,2967,85,3011,75C3054.5,65,3098,40,3120,27.5L3141.8,15L3141.8,150L3120,150C3098.2,150,3055,150,3011,150C2967.3,150,2924,150,2880,150C2836.4,150,2793,150,2749,150C2705.5,150,2662,150,2618,150C2574.5,150,2531,150,2487,150C2443.6,150,2400,150,2356,150C2312.7,150,2269,150,2225,150C2181.8,150,2138,150,2095,150C2050.9,150,2007,150,1964,150C1920,150,1876,150,1833,150C1789.1,150,1745,150,1702,150C1658.2,150,1615,150,1571,150C1527.3,150,1484,150,1440,150C1396.4,150,1353,150,1309,150C1265.5,150,1222,150,1178,150C1134.5,150,1091,150,1047,150C1003.6,150,960,150,916,150C872.7,150,829,150,785,150C741.8,150,698,150,655,150C610.9,150,567,150,524,150C480,150,436,150,393,150C349.1,150,305,150,262,150C218.2,150,175,150,131,150C87.3,150,44,150,22,150L0,150Z"
            ></path>
          </svg>
        </div>

        <div class="bg-white py-20 md:py-28">
          <div class="max-w-6xl mx-auto px-8 lg:px-8">
            <section class="mb-16">
              <h2 class="text-3xl font-semibold mb-4 text-center">
                Pricing for Head Spa Services
              </h2>
              <p class="text-center">Choose your relaxation experience.</p>
            </section>

            <section className="pb-8">
              <div className="flex flex-wrap justify-center gap-8 px-8 md:px-0">
                <div className="max-md:w-full bg-gray-100 rounded-lg p-6 shadow-xl">
                  <h3 className="text-2xl mb-2">
                    3 for 2 OFFER * LIMITED SPACES *
                  </h3>
                  <p>
                    1 hour · <strong>£160</strong>
                  </p>
                </div>
                <div className="max-md:w-full bg-gray-100 rounded-lg p-6 shadow-xl">
                  <h3 className="text-2xl mb-2">
                    Japanese Head Spa Waterfall Experience
                  </h3>
                  <p>
                    1 hour · <strong>£80</strong>
                  </p>
                </div>
                <div className="max-md:w-full bg-gray-100 rounded-lg p-6 shadow-xl">
                  <h3 className="text-2xl mb-2">
                    90 Minute Deluxe Head SPA Experience
                  </h3>
                  <p>
                    1 hour, 30 mins · <strong>£110</strong>
                  </p>
                </div>
                <div className="max-md:w-full bg-gray-100 rounded-lg p-6 shadow-xl">
                  <h3 className="text-2xl mb-2">
                    Japanese Waterfall Head Spa with Fire and Ice Facial
                  </h3>
                  <p>
                    1 hour, 30 mins · <strong>£110</strong>
                  </p>
                </div>
                <div className="max-md:w-full bg-gray-100 rounded-lg p-6 shadow-xl">
                  <h3 className="text-2xl mb-2">Mother and Daughter Time</h3>
                  <p>
                    1 hour, 30 mins · <strong>£110</strong>
                  </p>
                </div>
                <div className="max-md:w-full bg-gray-100 rounded-lg p-6 shadow-xl">
                  <h3 className="text-2xl mb-2">
                    Gift Idea: Head Spa with Flowers and Chocolates
                  </h3>
                  <p>
                    1 hour · <strong>£99</strong>
                  </p>
                </div>
                <div className="max-md:w-full bg-gray-100 rounded-lg p-6 shadow-xl">
                  <h3 className="text-2xl mb-2">
                    Block of 6 Head Spa Treatments
                  </h3>
                  <p>
                    60 mins each session · <strong>£300</strong>
                  </p>
                </div>
              </div>
            </section>

            <div class="flex justify-center text-center">
              <a
                target="_blank"
                href="https://bookings.gettimely.com/headspaceheadspa/bb/book"
                class="btn mt-4 text-white font-semibold py-2 px-4 rounded transition duration-300 inline-block  mb-8  md:mb-0"
              >
                Book Now
              </a>
            </div>
          </div>
        </div>

        <div class="">
          <svg
            id="wave"
            style={{ transform: "rotate(180deg)", transition: "0.3s" }}
            viewBox="0 0 1440 150"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                <stop stop-color="rgba(255, 223, 223, 1)" offset="0%"></stop>
                <stop stop-color="rgba(255, 223, 223, 1)" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              style={{ transform: "translate(0, 0px)", opacity: 1 }}
              fill="#ffffff"
              d="M0,90L21.8,95C43.6,100,87,110,131,105C174.5,100,218,80,262,70C305.5,60,349,60,393,70C436.4,80,480,100,524,95C567.3,90,611,60,655,42.5C698.2,25,742,20,785,35C829.1,50,873,85,916,95C960,105,1004,90,1047,70C1090.9,50,1135,25,1178,22.5C1221.8,20,1265,40,1309,40C1352.7,40,1396,20,1440,17.5C1483.6,15,1527,30,1571,37.5C1614.5,45,1658,45,1702,52.5C1745.5,60,1789,75,1833,75C1876.4,75,1920,60,1964,47.5C2007.3,35,2051,25,2095,30C2138.2,35,2182,55,2225,52.5C2269.1,50,2313,25,2356,30C2400,35,2444,70,2487,70C2530.9,70,2575,35,2618,30C2661.8,25,2705,50,2749,62.5C2792.7,75,2836,75,2880,77.5C2923.6,80,2967,85,3011,75C3054.5,65,3098,40,3120,27.5L3141.8,15L3141.8,150L3120,150C3098.2,150,3055,150,3011,150C2967.3,150,2924,150,2880,150C2836.4,150,2793,150,2749,150C2705.5,150,2662,150,2618,150C2574.5,150,2531,150,2487,150C2443.6,150,2400,150,2356,150C2312.7,150,2269,150,2225,150C2181.8,150,2138,150,2095,150C2050.9,150,2007,150,1964,150C1920,150,1876,150,1833,150C1789.1,150,1745,150,1702,150C1658.2,150,1615,150,1571,150C1527.3,150,1484,150,1440,150C1396.4,150,1353,150,1309,150C1265.5,150,1222,150,1178,150C1134.5,150,1091,150,1047,150C1003.6,150,960,150,916,150C872.7,150,829,150,785,150C741.8,150,698,150,655,150C610.9,150,567,150,524,150C480,150,436,150,393,150C349.1,150,305,150,262,150C218.2,150,175,150,131,150C87.3,150,44,150,22,150L0,150Z"
            ></path>
          </svg>
        </div>

        <div class="py-20 md:py-28">
          <div class="max-w-6xl mx-auto px-8 lg:px-8">
            <section class="md:text-center">
              <div class="max-w-lg mx-auto">
                <h2 class="text-3xl font-semibold mb-4">Where are we based?</h2>
                <p class="mb-2">
                  We are based in the centre of Chorley, just a 5-minute walk
                  from the train station, (with free 3-hour parking at the ASDA
                  nearby).
                </p>

                <a
                  href="https://maps.app.goo.gl/pJowDXfzkri7MidXA"
                  target="_blank"
                  class="underline inline-block mb-10"
                >
                  44 Pall Mall, Chorley Town Centre, Chorley, PR7 2LA
                </a>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2364.8777184376704!2d-2.6327008!3d53.6491525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b0c446a568e91%3A0xe5420d82b90cc337!2s44%20Pall%20Mall%2C%20Chorley%20PR7%202LA!5e0!3m2!1sen!2suk!4v1713190152519!5m2!1sen!2suk"
                  width="400"
                  height="300"
                  className="border text-center justify-center flex ml-auto mr-auto"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </section>
          </div>
        </div>

        <div class="bg-white">
          <svg
            id="wave"
            style={{ transform: "rotate(180deg)", transition: "0.3s" }}
            viewBox="0 0 1440 150"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                <stop stop-color="rgba(255, 223, 223, 1)" offset="0%"></stop>
                <stop stop-color="rgba(255, 223, 223, 1)" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              style={{ transform: "translate(0, 0px)", opacity: 1 }}
              fill="#F3F4F6"
              d="M0,90L21.8,95C43.6,100,87,110,131,105C174.5,100,218,80,262,70C305.5,60,349,60,393,70C436.4,80,480,100,524,95C567.3,90,611,60,655,42.5C698.2,25,742,20,785,35C829.1,50,873,85,916,95C960,105,1004,90,1047,70C1090.9,50,1135,25,1178,22.5C1221.8,20,1265,40,1309,40C1352.7,40,1396,20,1440,17.5C1483.6,15,1527,30,1571,37.5C1614.5,45,1658,45,1702,52.5C1745.5,60,1789,75,1833,75C1876.4,75,1920,60,1964,47.5C2007.3,35,2051,25,2095,30C2138.2,35,2182,55,2225,52.5C2269.1,50,2313,25,2356,30C2400,35,2444,70,2487,70C2530.9,70,2575,35,2618,30C2661.8,25,2705,50,2749,62.5C2792.7,75,2836,75,2880,77.5C2923.6,80,2967,85,3011,75C3054.5,65,3098,40,3120,27.5L3141.8,15L3141.8,150L3120,150C3098.2,150,3055,150,3011,150C2967.3,150,2924,150,2880,150C2836.4,150,2793,150,2749,150C2705.5,150,2662,150,2618,150C2574.5,150,2531,150,2487,150C2443.6,150,2400,150,2356,150C2312.7,150,2269,150,2225,150C2181.8,150,2138,150,2095,150C2050.9,150,2007,150,1964,150C1920,150,1876,150,1833,150C1789.1,150,1745,150,1702,150C1658.2,150,1615,150,1571,150C1527.3,150,1484,150,1440,150C1396.4,150,1353,150,1309,150C1265.5,150,1222,150,1178,150C1134.5,150,1091,150,1047,150C1003.6,150,960,150,916,150C872.7,150,829,150,785,150C741.8,150,698,150,655,150C610.9,150,567,150,524,150C480,150,436,150,393,150C349.1,150,305,150,262,150C218.2,150,175,150,131,150C87.3,150,44,150,22,150L0,150Z"
            ></path>
          </svg>
        </div>

        {/* <div class="bg-white">
          <div class="max-w-6xl mx-auto px-8 lg:px-8 py-20 md:py-28">
            <section class="flex flex-col md:flex-row items-center justify-between">
              <div class="max-w-lg">
                <h2 class="text-3xl font-semibold mb-4">Our service to you</h2>
                <p>
                  We specialize in the Japanese Head Spa treatment, offering a
                  warm, welcoming, and serene environment designed for
                  relaxation and rejuvenation. Our treatment focuses primarily
                  on the scalp, providing a calming experience in private,
                  comfortable rooms adorned with low-level mood lighting to set
                  the perfect ambiance for relaxation.
                </p>
                <a
                  target="_blank"
                  href="https://bookings.gettimely.com/headspaceheadspa/bb/book"
                  class="btn mt-4 text-white font-semibold py-2 px-4 rounded transition duration-300 inline-block  mb-8  md:mb-0"
                >
                  Book Now
                </a>
              </div>
              <img
                src="/b.jpg"
                alt="Book Now Image"
                class="w-full  max-w-md rounded-xl my-4 md:my-0 shadow-xl"
              />
            </section>
          </div>
        </div> */}

        <div class="">
          <svg
            id="wave"
            style={{ transform: "rotate(180deg)", transition: "0.3s" }}
            viewBox="0 0 1440 150"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                <stop stop-color="rgba(255, 223, 223, 1)" offset="0%"></stop>
                <stop stop-color="rgba(255, 223, 223, 1)" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              style={{ transform: "translate(0, 0px)", opacity: 1 }}
              fill="#ffffff"
              d="M0,90L21.8,95C43.6,100,87,110,131,105C174.5,100,218,80,262,70C305.5,60,349,60,393,70C436.4,80,480,100,524,95C567.3,90,611,60,655,42.5C698.2,25,742,20,785,35C829.1,50,873,85,916,95C960,105,1004,90,1047,70C1090.9,50,1135,25,1178,22.5C1221.8,20,1265,40,1309,40C1352.7,40,1396,20,1440,17.5C1483.6,15,1527,30,1571,37.5C1614.5,45,1658,45,1702,52.5C1745.5,60,1789,75,1833,75C1876.4,75,1920,60,1964,47.5C2007.3,35,2051,25,2095,30C2138.2,35,2182,55,2225,52.5C2269.1,50,2313,25,2356,30C2400,35,2444,70,2487,70C2530.9,70,2575,35,2618,30C2661.8,25,2705,50,2749,62.5C2792.7,75,2836,75,2880,77.5C2923.6,80,2967,85,3011,75C3054.5,65,3098,40,3120,27.5L3141.8,15L3141.8,150L3120,150C3098.2,150,3055,150,3011,150C2967.3,150,2924,150,2880,150C2836.4,150,2793,150,2749,150C2705.5,150,2662,150,2618,150C2574.5,150,2531,150,2487,150C2443.6,150,2400,150,2356,150C2312.7,150,2269,150,2225,150C2181.8,150,2138,150,2095,150C2050.9,150,2007,150,1964,150C1920,150,1876,150,1833,150C1789.1,150,1745,150,1702,150C1658.2,150,1615,150,1571,150C1527.3,150,1484,150,1440,150C1396.4,150,1353,150,1309,150C1265.5,150,1222,150,1178,150C1134.5,150,1091,150,1047,150C1003.6,150,960,150,916,150C872.7,150,829,150,785,150C741.8,150,698,150,655,150C610.9,150,567,150,524,150C480,150,436,150,393,150C349.1,150,305,150,262,150C218.2,150,175,150,131,150C87.3,150,44,150,22,150L0,150Z"
            ></path>
          </svg>
        </div>

        <div class="py-20 md:py-28">
          <div class="max-w-6xl mx-auto px-8 lg:px-8">
            <section class="mb-16">
              <h2 class="text-3xl font-semibold mb-4 text-center">
                Benefits of a Head Spa?
              </h2>
              <p class="text-center">Some of the many benefits include...</p>
            </section>

            <section>
              <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-10 px-8  md:px-0">
                <div class="bg-white rounded-lg p-6 shadow-xl">
                  <h3 class="text-2xl mb-2">Relieves stress</h3>
                  <p>Experience unparalleled relaxation and stress relief.</p>
                </div>
                <div class="bg-white rounded-lg p-6 shadow-xl">
                  <h3 class="text-2xl mb-2">Reduces tension</h3>
                  <p>
                    A perfect escape to reduce tension in your head, neck, and
                    shoulders.
                  </p>
                </div>
                <div class="bg-white rounded-lg p-6 shadow-xl">
                  <h3 class="text-2xl mb-2">Boosts blood circulation</h3>
                  <p>
                    Improves blood flow throughout your body, enhancing your
                    overall health.
                  </p>
                </div>
                <div class="bg-white rounded-lg p-6 shadow-xl">
                  <h3 class="text-2xl mb-2">Promotes healthy hair growth</h3>
                  <p>
                    Nourish your scalp and hair for stronger, healthier growth.
                  </p>
                </div>
                <div class="bg-white rounded-lg p-6 shadow-xl">
                  <h3 class="text-2xl mb-2">Improves mental clarity</h3>
                  <p>Clear your mind and enhance concentration and focus.</p>
                </div>
                <div class="bg-white rounded-lg p-6 shadow-xl">
                  <h3 class="text-2xl mb-2">Emotional well-being</h3>
                  <p>Uplift your mood and enhance your emotional well-being.</p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="bg-white">
          <svg
            id="wave"
            style={{ transform: "rotate(180deg)", transition: "0.3s" }}
            viewBox="0 0 1440 150"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                <stop stop-color="rgba(255, 223, 223, 1)" offset="0%"></stop>
                <stop stop-color="rgba(255, 223, 223, 1)" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              style={{ transform: "translate(0, 0px)", opacity: 1 }}
              fill="#F3F4F6"
              d="M0,90L21.8,95C43.6,100,87,110,131,105C174.5,100,218,80,262,70C305.5,60,349,60,393,70C436.4,80,480,100,524,95C567.3,90,611,60,655,42.5C698.2,25,742,20,785,35C829.1,50,873,85,916,95C960,105,1004,90,1047,70C1090.9,50,1135,25,1178,22.5C1221.8,20,1265,40,1309,40C1352.7,40,1396,20,1440,17.5C1483.6,15,1527,30,1571,37.5C1614.5,45,1658,45,1702,52.5C1745.5,60,1789,75,1833,75C1876.4,75,1920,60,1964,47.5C2007.3,35,2051,25,2095,30C2138.2,35,2182,55,2225,52.5C2269.1,50,2313,25,2356,30C2400,35,2444,70,2487,70C2530.9,70,2575,35,2618,30C2661.8,25,2705,50,2749,62.5C2792.7,75,2836,75,2880,77.5C2923.6,80,2967,85,3011,75C3054.5,65,3098,40,3120,27.5L3141.8,15L3141.8,150L3120,150C3098.2,150,3055,150,3011,150C2967.3,150,2924,150,2880,150C2836.4,150,2793,150,2749,150C2705.5,150,2662,150,2618,150C2574.5,150,2531,150,2487,150C2443.6,150,2400,150,2356,150C2312.7,150,2269,150,2225,150C2181.8,150,2138,150,2095,150C2050.9,150,2007,150,1964,150C1920,150,1876,150,1833,150C1789.1,150,1745,150,1702,150C1658.2,150,1615,150,1571,150C1527.3,150,1484,150,1440,150C1396.4,150,1353,150,1309,150C1265.5,150,1222,150,1178,150C1134.5,150,1091,150,1047,150C1003.6,150,960,150,916,150C872.7,150,829,150,785,150C741.8,150,698,150,655,150C610.9,150,567,150,524,150C480,150,436,150,393,150C349.1,150,305,150,262,150C218.2,150,175,150,131,150C87.3,150,44,150,22,150L0,150Z"
            ></path>
          </svg>
        </div>

        <div className="bg-white">
          <h2 class="text-3xl font-semibold pt-12 text-center">
            Find us on socials!
          </h2>

          <div class="flex justify-center items-center space-x-2 py-12 mx-auto max-w-md px-8  md:px-0">
            <div class="flex flex-1 min-w-0 p-2 justify-center">
              <a
                class="w-8 h-8"
                target="_blank"
                href="https://www.instagram.com/headspace_headspa"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#6051a7"
                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                  />
                </svg>
              </a>
            </div>
            <div class="flex flex-1 min-w-0 p-2 justify-center">
              <a
                class="w-8 h-8"
                target="_blank"
                href="https://www.facebook.com/headspaceheadspa"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#6051a7"
                    d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                  />
                </svg>
              </a>
            </div>
            <div class="flex flex-1 min-w-0 p-2 justify-center">
              <a
                class="w-8 h-8"
                target="_blank"
                href="https://www.tiktok.com/@headspamanchester"
              >
                <svg
                  style={{ transform: "translateY(-8px)" }}
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="52"
                  height="52"
                  viewBox="0 0 838 588"
                >
                  <path
                    fill="#6051a7"
                    d="M643.3 166.4c-29.2 0-56.2-9.7-77.8-26-24.8-18.7-42.7-46.2-49-77.8-1.6-7.8-2.4-15.9-2.5-24.2h-83.5v228.1l-.1 124.9c0 33.4-21.8 61.7-51.9 71.7-8.8 2.9-18.2 4.3-28 3.7-12.6-.7-24.3-4.5-34.6-10.6-21.8-13-36.5-36.6-36.9-63.7-.6-42.2 33.5-76.7 75.7-76.7 8.3 0 16.3 1.4 23.8 3.8V235c-7.9-1.2-15.9-1.8-24.1-1.8-46.2 0-89.4 19.2-120.3 53.8-23.3 26.1-37.3 59.5-39.5 94.5-2.8 45.9 14 89.6 46.6 121.8 4.8 4.7 9.8 9.1 15.1 13.2 27.9 21.5 62.1 33.2 98.1 33.2 8.1 0 16.2-.6 24.1-1.8 33.6-5 64.6-20.4 89.1-44.6 30.1-29.7 46.7-69.2 46.9-111.2l-.4-186.6c14.3 11.1 30 20.2 46.9 27.3 26.2 11.1 54 16.6 82.5 16.6v-83.1c.1.1-.2.1-.2.1z"
                  />
                </svg>
              </a>
            </div>
          </div>

          <footer class="text-center py-12">
            <p class="max-w-xl  mx-auto text-center  px-8  md:px-0">
              <small>
                <hr />
                <br />
                <br />
                Please be aware that Head Space HEAD SPA may not be able to
                accommodate clients who arrive more than 15 minutes late. While
                we might offer a shorter session where possible, the full price
                of the original service will still apply. In cases where no
                service can be provided to a late customer, we reserve the right
                to retain the 50% deposit as a penalty fee.
                <br />
                <br />
                Furthermore, if a client does not attend their appointment
                without prior notification, Head Space HEAD SPA will charge the
                full amount of the intended service. Clients are responsible for
                keeping their appointments, independent of receiving a reminder
                message or not.
              </small>
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default App;
